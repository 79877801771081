import React from "react";

const WorkCard = ({ imgPath, title, description }) => {
  return (
    <div className="space-y-4 hover:bg-[#3dcae1] p-4 rounded-xl">
      <div className="flex items-center gap-3">
        <img src={imgPath} className="w-7 h-7" alt="" />
        <h3 className="font-bold text-xl text-primary">{title}</h3>
      </div>
      <p className="text-primary text-justify">{description}</p>
    </div>
  );
};

export default WorkCard;
