import React from "react";
import Container from "../hoc/Container";

const ContactUs = () => {
  return (
    <Container className="bg-primary">
      <h2 className="text-white text-3xl font-black text-center">
        For Any Research or Collaborations Contact Us at: {"\n"}
        <a className="underline text-l" href="mailto:info@permiai.com">
          info@permiai.com
        </a>
      </h2>
    </Container>
  );
};

export default ContactUs;
