import Main from "./sections/Main";
import OurWork from "./sections/OurWork";
import Journey from "./sections/Journey";
import Testimonials from "./sections/Testimonials";
import TechStack from "./sections/TechStack";
import ContactUs from "./sections/ContactUs";

function App() {
  return (
    <>
      <Main />
      <OurWork />
      <Journey />
      {/* <Testimonials /> */}
      {/* <TechStack /> */}
      <ContactUs />
    </>
  );
}

export default App;
