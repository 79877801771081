import React, { useEffect, useState } from "react";
import { TypeAnimation } from "react-type-animation";

const Main = () => {
  const [isFixed, setIsFixed] = useState(false);
  const [isAnimationComplete, setIsAnimationComplete] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsAnimationComplete(true);
    }, 6300);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header
        className={`z-10 h-20 transition-all duration-300 ${
          isFixed
            ? "fixed bg-black shadow-lg top-0 w-full py-1 px-3 xl:px-40 h-20 sm:h-24"
            : "absolute top-6 left-0 xl:left-40"
        }`}
      >
        <img
          src={`${isFixed ? "/permi-logo.png" : "/permi-logo.png"}`}
          className={`h-16 sm:h-20 lg:h-full transition-opacity duration-500 ${
            isFixed ? "w-44 sm:w-60 lg:w-64" : "w-44 sm:w-60 lg:w-64"
          } ${isAnimationComplete ? "opacity-100" : "opacity-85"}`}
          alt="PERMI AI"
        />
      </header>
      <div className="h-screen bg-gradient-to-r  w-full from-[#3dcae1] to-[#00204b] hover:bg-gradient-to-l ">
        <div className=" w-full h-screen bg-[url('/public/bg.png')] bg-cover opacity-90 py-10 px-40 ">
          <div className="text-center text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full space-y-5 max-w-[1230px]">
            <TypeAnimation
              style={{ whiteSpace: "pre-line" }}
              wrapper="h1"
              // repeat={Infinity}
              speed={50}
              sequence={[
                500,
                "Using AI to select person specific",
                500,
                "Using AI to select person specific \n & \n cancer specific",
                500,
                "Using AI to select person specific \n & \n cancer specific treatment plan",
                500,
              ]}
              className="text-4xl xs:text-[42px] md:text-7xl leading-tight font-black"
            />
            <TypeAnimation sequence={[4100, "Precision Medicine AI."]} className="text-xl md:text-2xl" wrapper="p" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
