const journey = [
  {
    imgPath: "/ux.png",
    title: "An AI based Decision Support System to select the most effective drug for a given cancer.",
    description:
      "Completed ",
  },
  {
    imgPath: "/des_and_dev.png",
    title: "An ML model to predict drug dose size given the cancer and patient's information.",
    description:
      "Pending",
  },
  // {
  //   imgPath: "/launch.png",
  //   title: "Launch & Operate",
  //   description:
  //     "Last polishes. “One more thing” moments. Before taking the curtains off, there is still a lot to do. Infrastructure ready. Check. Systems secured. Check. Content ready and error free check. Check. Check. Check... We are ready to GO.",
  // },
  // {
  //   imgPath: "/evolve.png",
  //   title: "Evolve & Support",
  //   description:
  //     "The Go-Live is just a start. Knowledge transfer, support and adapt. We are behind the curtain. Watching your show. Watching you grow. We are here to help you evolve and help you scale.",
  // },
];

export default journey;
