import React from "react";
import JourneyCard from "../components/JourneyCard";
import journey from "../content/journey";
import Container from "../hoc/Container";

const Journey = () => {
  return (
    <>
      <Container>
        <div className="text-center space-y-3">
          <h2 className="text-primary text-4xl font-black">Our Journey</h2>
          <p className="text-[#282c47]">Our Journey towards Precision Medicine for Cancer using AI</p>
        </div>
        {journey.map(({ imgPath, title, description }, index) => (
          <JourneyCard key={index} imgPath={imgPath} title={title} description={description} index={index} />
        ))}
      </Container>
      {/* <img src="/services.png" className="w-full" alt="" /> */}
      <Container>
        <div className="text-xl md:text-2xl font-bold text-secondary text-center space-y-3">
          <p>
            We are on a mission to <span className="text-primary font-black">cure</span> all Cancers by revolutionizing
            treatment using AI.
          </p>
        </div>
      </Container>
    </>
  );
};

export default Journey;
