const works = [
  {
    imgPath: "/design.webp",
    title: "Lack of Effectiveness",
    description:
      "General drugs don't work for cancers. The existing chemotherapeutic drugs are toxic to all cells including cancer and normal cells. So the administration of these toxic agents kill the rapidly proliferating cancer cells as well as the normal cells which may lead to some serious side effects and may sometimes cause the death of patients. Untargeted radiotherapy suffers from a similar lack of specificity.",
  },
  {
    imgPath: "/dev.webp",
    title: "Dose Optimization",
    description: "smaller or larger (non-optimal) doses lead to long and painful chemotherapy.",
  },
  {
    imgPath: "/cloud.webp",
    title: "Immune to Anticancer Drugs",
    description:
      "Stem cells have unique mechanisms to protect themselves from harmful agents, such as the ATP-binding cassette transporter proteins that facilitate substrate efflux. Cancer stem cells maintain this property, including the Breast Cancer Resistance protein (BCRP-ABCG2), which is responsible for drug efflux in breast cancer and is highly expressed in stem cell subpopulations of Acute Myelogenous Leukemia (AML). CSCs can also adopt alternative strategies such as expressing aldehyde dehydrogenase (ALDH) to confer resistance to alkylating agents.",
  },
  {
    imgPath: "/devs.webp",
    title: "Limitations of Conventional Chemotherapeutic Agents",
    description:
      "The existing chemotherapeutic drugs are toxic to all cells including cancer and normal cells. So the administration of these toxic agents kill the rapidly proliferating cancer cells as well as the normal cells which may lead to some serious side effects and may sometimes cause the death of patients. Untargeted radiotherapy suffers from a similar lack of specificity.",
  },
];

export default works;
